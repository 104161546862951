import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'

function App() {
	const theme = createTheme({
		palette: {
			primary: {
				main: '#7EB6D7' ,
			},
			secondary: {
				main: '#4D5264',
			}

		}

	})



  return (
	  <ThemeProvider theme={theme}>
	<>
	  <header style={{margin: '10px 20px'}}>
		<Stack direction='row' jutifyItems='space-between'>
			<Typography variant='h4' sx={{color: 'pink'}}>Fed Reaction Function</Typography>
	  	


	  	</Stack>


	  </header>
	  <main style={{maxWidth: '1500px', minHeight: '85vh', margin: '50px auto', padding: '10px 20px'}}>
	  <Box sx={{backgroundColor: theme.palette.primary.main, pt: 7, pb: 7, pl: 1, pr: 1}}>
	  <Typography variant='h3'>Data & analysis to help you understand the Fed</Typography>
	  </Box>
	<Divider sx={{margin: '30px 0'}} />
	  <Typography variant='h2'>Most important data</Typography>
	  <Stack direction='row' spacing={3} mt={4}>
	  <Button variant='outlined'>Inflation: x%</Button>
	  <Button variant='outlined'>Unemployment: y%</Button>
	  </Stack>

	<Divider sx={{margin: '30px 0'}} />
	  <Typography variant='h2'>Fed Reaction Function Parameters</Typography>
	  <Stack direction='row' spacing={3} mt={4}>
	  <Button variant='outlined'>Inflation</Button>
	  <Button variant='outlined'>Unemployment</Button>
	  <Button variant='outlined'>Growth</Button>
	  </Stack>

	<Divider sx={{margin: '30px 0'}} />

	  <Typography variant='h2'>Key upcoming dates</Typography>
	  <p>5th May 2022: ......</p>
	  <p>8th November 2022: .......</p>


	  </main>
	  <footer style={{backgroundColor: '#ccc', padding: '10px 20px'}}>
	  <p>Copyright 2022 Fed Reaction Function</p>
	  <p>Terms</p>
	  <p>Privacy</p>


	  </footer>




	  </>
	  </ThemeProvider>
  );
}

export default App;
